<template lang="pug">
h2(v-if='lessThan("tablet")', class='color-primary') {{ variablesList.reviewsTitle }}
h2(v-if='greaterThan("mobileWide")', class='text-center short-text') 
	| {{ variablesList.reviewsTitle }}
	|
	span(class='Accent') {{ variablesList.reviewsAccentTitle }}
	|
	| {{ variablesList.reviewsSubtitle }}

SliderReviews(:reviews='reviewsState')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();
const { reviewsState, fetchReviews } = useReviews();

/**
 * Define fetch
 */
await fetchVariables();
await fetchReviews();
</script>
