import { Common } from '~/models/common';
import { Reviews } from '~/models/reviews';
import { ReviewsGroup } from '~/models/reviews-group';

const collection = 'reviews';
const collectionGroup = 'reviews_group';

export const reviewsService = {
	fetch: async (isVideo: boolean = false): Promise<Reviews.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Reviews.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
					...(isVideo ? { isVideo: true } : { isVideo: false }),
				},
				fields: ['*', 'photo.*', 'group.*'],
			},
		});
	},

	fetchGroups: async (): Promise<ReviewsGroup.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<ReviewsGroup.Model>({
			collection: collectionGroup,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*'],
			},
		});
	},
};
