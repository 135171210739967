<template lang="pug">
div(class='SliderReviews', :class='{ NoMargin: video }')
	UiCarousel(
		class='SliderReviews--Carousel',
		:perView='video ? (greaterThan("tablet") ? 3 : greaterThan("mobileWide") ? 2 : 1.2) : greaterThan("tablet") ? 2.2 : greaterThan("mobileWide") ? 1.6 : 1.2',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(
					v-for='(review, index) in reviews',
					:key='review.id',
					v-bind='carouselItemProps'
				)
					div(class='SliderReviews--Carousel--Item', :class='{ NoPadding: video }')
						div(v-if='!video', class='Review')
							div(class='User')
								UiImage(:image='review.photo', width='80px', lazy)
								div(class='Name')
									| {{ $f.splitText(review.name, 1, 1) }}
									br
									| {{ $f.splitText(review.name, 1, 2) }}
							div(class='Text')
								p(v-if='isFullReview(index) || review.text.length < 200') {{ review.text }}
								p(v-else) 
									| {{ $f.trimString(review.text, 200) }}
									|
									span(class='Link', @click='showFullReview(index)') Развернуть ↓
							div(class='Link')
								UiButton(
									:to='review.link',
									:size='greaterThan("mobileWide") ? "md" : "sm"',
									target='_blank',
									variant='secondary',
									iconRight='system/arrow-top-right',
									:iconRightSize='greaterThan("mobileWide") ? "sm" : "xs"',
									rounded
								) Ссылка на отзыв
						template(v-if='video')
							NuxtLink(v-if='review.text === null', class='VideoReview', :href='review.link', target='_blank')
								UiIcon(name='social/youtube', size='custom', class='Icon')
								UiImage(:image='review.photo', width='500px', lazy)
							div(v-else, class='VideoReview', @click='openIframeModal(index)')
								UiIcon(name='social/youtube', size='custom', class='Icon')
								UiImage(:image='review.photo', width='500px', lazy)
</template>

<script lang="ts" setup>
import { UiCarousel } from '#components';
import { Reviews } from '~/models/reviews';
import { Modal } from '~/models/modal';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define props
 */
const { reviews = [], video = false } = defineProps<{
	reviews: Reviews.Model[];
	video?: boolean;
}>();

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
const { modalState, modalShow } = useModal();

/**
 * Open iframe modal
 */
const openIframeModal = (index: number) => {
	modalState.value.type = Modal.Type.IFRAME;
	modalState.value.header = reviews[index].name;
	modalState.value.iframe = reviews[index].text;

	modalShow();
};

/**
 * Full review
 */
const expandedReview = ref<number[]>([]);
const isFullReview = (index: number) => expandedReview.value.includes(index);
const showFullReview = (index: number) => expandedReview.value.push(index);
</script>

<style lang="less" scoped>
.SliderReviews {
	margin: 1.25rem 0 0 0;
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&.NoMargin {
		margin: 0 !important;
	}

	&--Carousel {
		.grid(1 span; 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			padding: 1.25rem 1.25rem;
			text-decoration: none;
			.box(grid; 100%; 100%; fade(@ColorBase, 5%); 1rem; 1fr; 1fr; stretch; start);
			.border-radius(@BorderRadiusMedium);

			&.NoPadding {
				padding: 0 !important;
			}

			& > .Review {
				.grid(1 span, 1 span);
				.box(grid; 100%; 100%; none; 0; auto 1fr auto; 1fr; stretch; start);

				& > .User {
					.grid(1 span);
					.box(grid; 100%; auto; none; 1rem; 1fr; auto 1fr; center; start);
					& > img {
						object-fit: cover;
						.box(block; 3.125rem; 3.125rem);
						.border-radius(@BorderRadiusFull);
					}
					& > .Name {
						.grid(1 span);
						.text(@ColorBase; 1.4em 0.875rem @medium);
					}
				}
				& > .Text {
					.grid(1 span);
					.box(block; auto; auto; none);
					.text(@ColorBase; 1.8em 0.75rem @regular);
					& .Link {
						padding: 0.25rem 0.5rem;
						.cursor(pointer);
						.text(@ColorPrimary; 1em 0.875rem @regular);
						.box(inline-block; auto; auto; fade(@ColorPrimary, 5%));
						.border-radius(0.3125rem);
						.transition(all; 250ms; 'sine');
						&:hover {
							.text(@ColorWhite);
							.background(@ColorPrimary);
							.box-shadow(0 0.375rem 0.75rem -0.25rem fade(@ColorPrimary, 20%));
						}
					}
				}
				& > .Link {
					margin: 1rem 0 0 0;
					.grid(1 span);
					.box(block; auto; auto; none);
				}
			}

			& > .VideoReview {
				.cursor(pointer);
				.relative(1; hidden);
				.grid-aspect(4.5; 3);
				.box(flex; 100%; auto; none; center; center; center; nowrap row);
				.border-radius(@BorderRadiusSmall);
				& > img {
					object-fit: cover;
					.box(block; 100%; 100%);
				}
				& > .Icon {
					.absolute(1);
					.box(block;	6.25rem; 6.25rem);
					.text(fade(@ColorWhite, 50%));
					.transition(all; 250ms; 'sine');
				}

				&:hover {
					& > .Icon {
						.scale(1.2);
					}
				}
			}

			@media all and (min-width: @tablet) and (max-width: (@laptop - 1px)) {
				& > .Review {
					& > .User {
						.text(@ColorBase; 1.4em 1rem @medium);
						& > img {
							.box(3.75rem; 3.75rem);
						}
					}
					& > .Text {
						.text(@ColorBase; 1.8em 0.875rem @regular);
					}
				}
			}
			@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
				padding: 1.5rem 1.5rem;
				& > .Review {
					& > .User {
						.text(@ColorBase; 1.4em 1rem @medium);
						& > img {
							.box(4.375rem; 4.375rem);
						}
					}
					& > .Text {
						.text(@ColorBase; 1.8em 0.875rem @regular);
					}
				}
			}
			@media all and (min-width: @desktop) {
				padding: 2rem 2rem;
				& > .Review {
					.box(grid; 100%; 100%; none; 0 3rem; 1fr auto; auto 1fr; stretch; start);
					& > .User {
						.box(grid; 100%; auto; none; 1rem; auto 1fr; 1fr; start; start);
						.grid(1 3, 1 2);
						& > img {
							.box(5rem; 5rem);
						}
						& > .Name {
							padding: 0 0.5rem;
							.grid(1 span);
							.text(@ColorBase; 1.4em 1rem @medium);
						}
					}
					& > .Text {
						.grid(1 2, 2 3);
						.text(@ColorBase; 1.8em 1rem @regular);
					}
					& > .Link {
						.grid(2 3, 2 3);
					}
				}
			}
		}
	}

	@media all and (min-width: @desktop) {
		margin: 3rem 0 0 0;
	}
}
</style>
