import { Reviews } from '~/models/reviews';
import { ReviewsGroup } from '~/models/reviews-group';
import { reviewsService } from '~/services/reviews';

export const useReviewsState = (isVideo: boolean = false) => ({
	reviewsState: useState<Reviews.Model[]>(
		isVideo ? 'reviewsState__video' : 'reviewsState',
		() => []
	),
	reviewsLoaded: useState<boolean>(
		isVideo ? 'reviewsLoaded__video' : 'reviewsLoaded',
		() => false
	),
	reviewsPending: useState<boolean>(
		isVideo ? 'reviewsPending__video' : 'reviewsPending',
		() => true
	),
	reviewsGroupsState: useState<ReviewsGroup.Model[]>('reviewsGroupsState', () => []),
	reviewsGroupsLoaded: useState<boolean>('reviewsGroupsLoaded', () => false),
	reviewsGroupsPending: useState<boolean>('reviewsGroupsPending', () => true),
});

export const useReviews = (isVideo: boolean = false) => {
	const {
		reviewsState,
		reviewsLoaded,
		reviewsPending,
		reviewsGroupsState,
		reviewsGroupsLoaded,
		reviewsGroupsPending,
	} = useReviewsState(isVideo);

	/**
	 * Fetch reviews
	 */
	const fetchReviews = async () => {
		if (reviewsLoaded.value) return;

		reviewsPending.value = true;

		reviewsState.value = await reviewsService.fetch(isVideo);

		reviewsLoaded.value = true;
		reviewsPending.value = false;
	};

	/**
	 * Fetch reviews groups
	 */
	const fetchReviewsGroups = async () => {
		if (reviewsGroupsLoaded.value) return;

		reviewsGroupsPending.value = true;

		reviewsGroupsState.value = await reviewsService.fetchGroups();

		reviewsGroupsLoaded.value = true;
		reviewsGroupsPending.value = false;
	};

	/**
	 * Refresh reviews
	 */
	const refreshReviews = async () => {
		reviewsLoaded.value = false;

		await fetchReviews();
	};

	/**
	 * Refresh reviews groups
	 */
	const refreshReviewsGroups = async () => {
		reviewsGroupsLoaded.value = false;

		await fetchReviewsGroups();
	};

	return {
		reviewsState,
		reviewsPending,
		reviewsGroupsState,
		reviewsGroupsPending,

		fetchReviews,
		refreshReviews,
		fetchReviewsGroups,
		refreshReviewsGroups,
	};
};
